import forEach from '../../../../javascripts/dom/forEach';

const flyoutNavs = document.querySelectorAll('[data-js-module="o-flyout-nav"]');

const init = async () => {
  const { default: FlyoutNav } = await import(/* webpackChunkName: o-flyout-nav */ './flyout-nav');

  forEach(flyoutNavs, (index, element) => new FlyoutNav(element));
};

if (flyoutNavs.length) init();
