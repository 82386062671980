const processSection = document.querySelector("[data-js-module='m-process-section']");

const init = async () => {
  const { default: ProcessSection } = await import(
    /* webpackChunkName: "m-process-section" */ './process-section.js'
  );

  new ProcessSection(processSection);
};

if (processSection) init();
