import forEach from '../../../../javascripts/dom/forEach';

const videos = document.querySelectorAll("[data-js-module='m-video-teaser']");

const init = async () => {
  const { default: Video } = await import(
    /* webpackChunkName: "m-video-teaser" */ './video-teaser.js'
  );

  forEach(videos, (index, element) => new Video(element));
};

if (videos.length) init();
