import forEach from '../../../../javascripts/dom/forEach';

const teaserContainers = document.querySelectorAll('[data-js-module="o-teaser-container"]');

const init = async () => {
  const { default: TeaserContainer } = await import(
    /* webpackChunkName: "o-teaser-container" */ './teaser-container.js'
  );

  forEach(teaserContainers, (index, element) => TeaserContainer(element));
};

if (teaserContainers.length) init();
