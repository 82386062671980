// Use custom modernizr build by modernizr-loader configured in .modernizrrc
import 'modernizr';

/* IE 11 does not support Symbol.iterator but Promise.all which is used
 * by dynamic module loading needs it
 *
 * TODO should be automatically polyfilled by babel-preset-env:
 * https://github.com/babel/babel/pull/7400
 */
import 'core-js/modules/web.dom.iterable';

/* IE 11 does not support Object.assign but webpacks dynamic module
 * loading, which is inserted after babel-loader has run, needs it.
 */
import 'core-js/modules/es6.object.assign';

// Use what-input to track input method
import 'what-input';

// Intersection Observer Polyfill
import 'intersection-observer';

// SmoothScroll polyfill for scrollTo
import smoothscroll from 'smoothscroll-polyfill';
import objectFitImages from 'object-fit-images';

// Sentry
import * as Sentry from '@sentry/browser';

// Shims
import './shims/closest';
import './shims/custom-event';
import forEach from './dom/forEach';
import trackEvent from './helpers/trackEvent';

smoothscroll.polyfill();
objectFitImages();

// all links with target="_blank" attribute should hav an attribute rel="noopener". This has performance and security reasons https://developers.google.com/web/tools/lighthouse/audits/noopener
forEach(document.querySelectorAll("[target='_blank']"), (index, link) => {
  link.setAttribute('rel', 'noopener');
  link.addEventListener('click', e => trackEvent('External_Link_Click', e.target.href));
});

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: process.env.SENTRY_DSN });
}
